import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    blockSMS: null,
    reSignInUser: false,
    key: 1,
    reporting: undefined,
    openMenu: true,
    isAuthenticated: false,
    alertSMS: false,
    commandeCount: -1,
    paymentMethodsNew: -1,
    permission: 0,
    perms: [
      {
        id: 10,
        label: 'Hôte de caisse',
      },
      {
        id: 20,
        label: 'Responsable',
      },
      {
        id: 30,
        label: 'Adhérent',
      },
      {
        id: 40,
        label: 'Centrale',
      },
      {
        id: 50,
        label: 'Administrateur',
      },
    ],
    action: {
      disableEstimateButton: false,
      disabledBContentButton: false,
    }
  },
  mutations: {},
  actions: {}
});
