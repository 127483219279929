import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: {
        title: 'Accueil',
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/Home.vue')
    },
    {
      name: 'SignIn',
      path: '/auth/signin',
      meta: {
        title: 'Connexion',
        session_required: false
      },
      component: () => import('./views/Login/SignIn/SignIn.vue')
    },
    {
      path: '/commandespriv/',
      name: 'CommandesAdherant',
      props: {},
      meta: {
        nameMaster: 'CommandesAdherant',
        title: 'Commandes Adhérent',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/Commandes/Adherant.vue')
    },
    {
      path: '/commandes/',
      name: 'Commandes',
      props: {},
      meta: {
        nameMaster: 'Commandes',
        title: 'Commandes Adhérent',
        master: true,
        session_required: true,
        permission: 40,
      },
      component: () => import('./views/Commandes/Home.vue')
    },
    {
      path: '/commandes/locales',
      name: 'CommandesLocales',
      props: {},
      meta: {
        nameMaster: 'Commandes',
        title: 'Commandes locales',
        master: true,
        session_required: true,
        permission: 30,
      },
      component: () => import('./views/Commandes/Locales.vue')
    },
    {
      path: '/commandes/boost',
      name: 'CommandesBoost',
      props: {},
      meta: {
        nameMaster: 'Commandes',
        title: 'Commandes boost',
        master: true,
        session_required: true,
        permission: 40,
      },
      component: () => import('./views/Commandes/Boost.vue')
    },
    {
      path: '/contacts',
      name: 'Contact',
      meta: {
        nameMaster: 'Contact',
        title: 'Fiche clients',
        session_required: true,
        permission: 10,
      },
      component: () => import('./views/Client/Home.vue'),
      children: [
        {
          name: 'ContactCreate',
          path: 'create',
          meta: {
            nameMaster: 'Contact',
            title: 'Créer un contact',
            session_required: true,
            permission: 10,
          },
          component: () => import('./views/Client/Modal/Contact/UpsertContact.vue')
        },
        {
          name: 'ContactCard',
          path: 'p/:contactId',
          meta: {
            nameMaster: 'Contact',
            title: 'Contact',
            session_required: true,
            permission: 10,
          },
          component: () => import('./views/Client/Card/Card.vue'),
        }
      ]
    },
    {
      path: '/campaignoutlocal',
      name: 'CampaignOutLocal',
      props: {},
      meta: {
        nameMaster: 'CampaignOutLocal',
        title: 'Campagnes réalisées',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/CampaignOut/CampaignOutLocal.vue')
    },
    {
      path: '/campaignouttrigger',
      name: 'CampaignOutTrigger',
      props: {},
      meta: {
        nameMaster: 'CampaignOutLocal',
        title: 'Campagnes Trigger',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/CampaignOut/CampaignOutTrigger.vue')
    },
    {
      path: '/campaignoutnational',
      name: 'CampaignOutNational',
      props: {},
      meta: {
        nameMaster: 'CampaignOutLocal',
        title: 'Campagnes nationales',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/CampaignOut/CampaignOutNational.vue')
    },
    {
      path: '/campaignoutboost',
      name: 'CampaignOutBoost',
      props: {},
      meta: {
        nameMaster: 'CampaignOutLocal',
        title: 'Campagnes Boost',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/CampaignOut/CampaignOutBoost.vue')
    },
    {
      path: '/commercial',
      name: 'Commercial',
      props: {},
      meta: {
        nameMaster: 'Catalog',
        title: 'Catalogue',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/Campaign/Commercial.vue')
    },
    {
      path: '/participative',
      name: 'Participative',
      props: {},
      meta: {
        nameMaster: 'Catalog',
        title: 'Catalogue Boost',
        master: true,
        session_required: true,
        permission: 50,
      },
      component: () => import('./views/Campaign/Participative.vue')
    },
    {
      path: '/catalog',
      name: 'Catalog',
      props: {},
      meta: {
        nameMaster: 'Catalog',
        title: 'Campagnes locales',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/Campaign/Home.vue'),
      children: [
        {
          name: 'Campaign',
          path: 'order/:orderId',
          props: {},
          meta: {
            nameMaster: 'Catalog',
            session_required: true,
            permission: 20,
          },
          component: () => import('./views/Campaign/Campaign.vue'),
        },
        // {
        //   name: 'Target',
        //   path: 'target/:orderId',
        //   props: {},
        //   meta: {
        //     nameMaster: 'Catalog',
        //     session_required: true,
        //     permission: 20,
        //   },
        //   component: () => import('./views/Campaign/Target.vue'),
        // },
        // {
        //   name: 'Content',
        //   path: 'content/:orderId',
        //   props: {},
        //   meta: {
        //     nameMaster: 'Catalog',
        //     session_required: true,
        //     permission: 20,
        //   },
        //   component: () => import('./views/Campaign/Content.vue'),
        // },
        // {
        //   name: 'Complet',
        //   path: 'complet',
        //   props: {},
        //   meta: {
        //     nameMaster: 'Catalog',
        //     session_required: true,
        //     permission: 20,
        //   },
        //   component: () => import('./views/Campaign/Complet.vue'),
        // },
      ],
    },
    {
      path: '/reporting',
      name: 'Reporting',
      meta: {
        nameMaster: 'Reporting',
        title: 'Reporting',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/Reporting/Home.vue'),
    },
    {
      path: '/satisfaction',
      name: 'Satisfaction',
      meta: {
        nameMaster: 'Satisfaction',
        title: 'Satisfaction',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/Satisfaction/Home.vue'),
    },
    {
      path: '/reporting_v2',
      name: 'ReportingV2',
      meta: {
        nameMaster: 'ReportingV2',
        title: 'ReportingV2',
        master: true,
        session_required: true,
        permission: 20,
      },
      component: () => import('./views/ReportingV2/Home.vue'),
    },
    {
      path: '/administration',
      name: 'Administration',
      meta: {
        nameMaster: 'Administration',
        title: 'Administration',
        master: true,
        session_required: true,
        permission: 30,
      },
      component: () => import('./views/Admin/Home.vue'),
    },
    {
      path: '/configuration',
      name: 'Configuration',
      meta: {
        nameMaster: 'Configuration',
        title: 'Configuration',
        master: true,
        session_required: true,
        permission: 50,
      },
      component: () => import('./views/Configuration/Home.vue'),
    },
    {
      path: '/exports',
      name: 'Exports',
      meta: {
        nameMaster: 'Exports',
        title: 'Exports',
        session_required: true,
        permission: 30,
      },
      component: () => import('./views/Exports/Exports.vue'),
      children: [
        {
          name: 'ExportFileExecuting',
          path: 'success',
          props: {},
          meta: {
            title: 'Mon export',
            nameMaster: 'Exports',
            session_required: true,
            permission: 30,
          },
        }
      ]
    },
    {
      path: '/payment_methods',
      name: 'PaymentMethods',
      meta: {
        nameMaster: 'PaymentMethods',
        title: 'Moyen de paiment',
        master: true,
        session_required: true,
        permission: 40,
      },
      component: () => import('./views/PaymentMethods/Home.vue'),
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.session_required) {
    if (router.app.$session.exists()) {
      next()
    } else {
      next({
        path: '/auth/signin',
        query: {
          query: to.path,
          fp: to.query.fp === false ? to.query.fp : false
        }
      })
    }
  } else {
    if (router.app.$session.exists()) {
      next({path: '/'})
    } else {
      next()
    }
  }
});

export default router;
