<template>
  <div>
    <router-link to="/" class="mainBackground">
      <img id="logo" :src="buildSettings.logo" alt="SPORT 2000 LOGO" :class="{whiteBackgroundLogo: buildSettings.env === 'development' && buildSettings.otherLogo}">
    </router-link>

    <template v-if="this.$store.state.permission >= 40 || buildSettings.env !== 'production'">
      <br>
      <div class="has-text-centered has-text-danger">
        <p class="title is-5"></p>Environnement : <br>
        <p class="subtitle is-6 has-text-weight-bold has-text-danger">
          <template v-if="buildSettings.env === 'staging'">
            RECETTE
          </template>
          <template v-else>
            {{ buildSettings.env.toUpperCase() }}
          </template>
          {{ buildSettings.version }}
          <b-tooltip
            v-if="false"
            multilined
            position="is-top"
            class="b-tooltip-custom"
            type="is-dark"
            size="is-small"
            :label="`Version actuelle : ${buildSettings.version}`">
            <b-icon
              custom-class="is-size-7"
              size="is-small"
              icon="info-circle"
              pack="fas">
            </b-icon>
          </b-tooltip>
        </p>
      </div>
    </template>
    <b-menu class="is-size-7 menu-custom">
      <b-menu-list>
        <b-menu-item
          v-if="this.$permission.get('Home') <= this.$store.state.permission"
          :active="$route.name === 'Home'"
          :icon-pack="[$route.name === 'Home' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Home' }"
          icon="home"
          label="Accueil">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('Contact') <= this.$store.state.permission"
          :active="['Contact', 'ContactCard'].includes($route.name)"
          :icon-pack="[['Contact', 'ContactCard'].includes($route.name) ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Contact' }"
          icon="id-card-alt"
          label="Client">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('CommandesAdherant') <= this.$store.state.permission"
          :active="$route.name === 'CommandesAdherant'"
          :icon-pack="[$route.name === 'CommandesAdherant' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'CommandesAdherant' }"
          icon="cart-arrow-down"
          label="Commande">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('Commandes') <= this.$store.state.permission"
          :active="$route.name === 'Commandes'"
          :icon-pack="[$route.name === 'Commandes' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Commandes' }"
          icon="shopping-cart">
          <template #label>
            Commande à valider
            <b-tag
              class="is-pulled-right is-size-7"
              size="is-medium"
              rounded>
              <template v-if="$store.state.commandeCount > 0">{{ $store.state.commandeCount }}</template><template v-else>0</template>
            </b-tag>
          </template>
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('Catalog') <= this.$store.state.permission"
          :expanded="['Catalog', 'Participative'].includes($route.name)"
          :icon-pack="[$route.name === 'Catalog' || $route.name === 'Participative' ? 'fas' : 'far'].toString()"
          icon="mail-bulk"
          class="not-active">
          <template #label="props">
            Catalogue campagnes
            <b-icon class="is-pulled-right" icon-pack="far" :icon="props.expanded ? 'angle-down' : 'angle-up'"></b-icon>
          </template>

          <b-menu-item
            v-if="this.$permission.get('Catalog') <= this.$store.state.permission"
            :active="$route.name === 'Catalog'"
            :icon-pack="[$route.name === 'Catalog' ? 'fas' : 'far'].toString()"
            tag="router-link"
            :to="{ name: 'Catalog' }"
            icon="map-marked"
            label="Local">
          </b-menu-item>

          <b-menu-item
            v-if="this.$permission.get('Participative') <= this.$store.state.permission"
            :active="$route.name === 'Participative'"
            :icon-pack="[$route.name === 'Participative' ? 'fas' : 'far'].toString()"
            tag="router-link"
            :to="{ name: 'Participative' }"
            icon="tachometer-fastest"
            label="Boost">
          </b-menu-item>
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('CampaignOutLocal') <= this.$store.state.permission"
          :expanded="['CampaignOutLocal', 'CampaignOutNational', 'CampaignOutTrigger', 'CampaignOutBoost'].includes($route.name)"
          :icon-pack="[['CampaignOutLocal', 'CampaignOutNational', 'CampaignOutTrigger', 'CampaignOutBoost'].includes($route.name) ? 'fas' : 'far'].toString()"
          icon="chart-bar"
          class="not-active">
          <template #label="props">
            Campagnes réalisées
            <b-icon class="is-pulled-right" icon-pack="far" :icon="props.expanded ? 'angle-down' : 'angle-up'"></b-icon>
          </template>
          <b-menu-item
            v-if="this.$permission.get('CampaignOutLocal') <= this.$store.state.permission"
            :active="$route.name === 'CampaignOutLocal'"
            :icon-pack="[$route.name === 'CampaignOutLocal' ? 'fas' : 'far'].toString()"
            tag="router-link"
            :to="{ name: 'CampaignOutLocal' }"
            icon="map-marked-alt"
            label="Campagnes locales">
          </b-menu-item>

          <b-menu-item
            v-if="this.$permission.get('CampaignOutNational') <= this.$store.state.permission"
            :active="$route.name === 'CampaignOutNational'"
            :icon-pack="[$route.name === 'CampaignOutNational' ? 'fas' : 'far'].toString()"
            tag="router-link"
            :to="{ name: 'CampaignOutNational' }"
            icon="map"
            label="Campagnes nationales">
          </b-menu-item>

          <b-menu-item
            v-if="this.$permission.get('CampaignOutTrigger') <= this.$store.state.permission"
            :active="$route.name === 'CampaignOutTrigger'"
            :icon-pack="[$route.name === 'CampaignOutTrigger' ? 'fas' : 'far'].toString()"
            tag="router-link"
            :to="{ name: 'CampaignOutTrigger' }"
            icon="bullseye-pointer"
            label="Campagnes triggers">
          </b-menu-item>

          <b-menu-item
            v-if="this.$permission.get('CampaignOutBoost') <= this.$store.state.permission"
            :active="$route.name === 'CampaignOutBoost'"
            :icon-pack="[$route.name === 'CampaignOutBoost' ? 'fas' : 'far'].toString()"
            tag="router-link"
            :to="{ name: 'CampaignOutBoost' }"
            icon="tachometer-alt-fastest"
            label="Campagnes boosts">
          </b-menu-item>
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('Reporting') <= this.$store.state.permission && false"
          :active="$route.name === 'Reporting'"
          :icon-pack="[$route.name === 'Reporting' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Reporting' }"
          icon="chart-area"
          label="Reporting">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('ReportingV2') <= this.$store.state.permission"
          :active="$route.name === 'ReportingV2'"
          :icon-pack="[$route.name === 'ReportingV2' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'ReportingV2' }"
          icon="chart-line-up"
          label="Reporting">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('Satisfaction') <= this.$store.state.permission"
          :active="$route.name === 'Satisfaction'"
          :icon-pack="[$route.name === 'Satisfaction' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Satisfaction' }"
          icon="chart-area"
          label="Satisfaction">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('PaymentMethods') <= this.$store.state.permission"
          :active="$route.name === 'PaymentMethods'"
          :icon-pack="[$route.name === 'PaymentMethods' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'PaymentMethods' }"
          icon="money-check-dollar-pen">
          <template #label>
            Moyen de paiements
            <b-tag
                class="is-pulled-right is-size-7"
                size="is-medium"
                rounded>
              <template v-if="$store.state.paymentMethodsNew > 0">{{ $store.state.paymentMethodsNew }}</template><template v-else>0</template>
            </b-tag>
          </template>
        </b-menu-item>

        <!--<b-menu-item
          v-if="this.$permission.get('Exports') <= this.$store.state.permission && false"
          :active="$route.name === 'Exports'"
          :icon-pack="[$route.name === 'Exports' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Exports' }"
          icon="file-export"
          label="Exports">
        </b-menu-item>-->

        <b-menu-item
          v-if="this.$permission.get('Administration') <= this.$store.state.permission"
          :active="$route.name === 'Administration'"
          :icon-pack="[$route.name === 'Administration' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Administration' }"
          icon="users-cog"
          label="Administration">
        </b-menu-item>

        <b-menu-item
          v-if="this.$permission.get('Configuration') <= this.$store.state.permission"
          :active="$route.name === 'Configuration'"
          :icon-pack="[$route.name === 'Configuration' ? 'fas' : 'far'].toString()"
          tag="router-link"
          :to="{ name: 'Configuration' }"
          icon="cogs"
          label="Configuration">
        </b-menu-item>

        <b-menu-item
          class="is-hidden-desktop"
          icon="sign-out">
          <template #label="props">
            <Logout label="Déconnexion" />
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<style>
.whiteBackgroundLogo {
    margin-top: 15px!important;
    background-color: white;
    padding: 15px 15px!important;
    border-radius: 5px;
}
</style>

<script>
import Logout from "@/components/Logout";

export default{
  components: {
    Logout,
  },
  data () {
    return {
      buildSettings: {
        version: process.env.VUE_APP_VERSION,
        latestVersion: false,
        env: process.env.VUE_APP_ENV,
        changelogs: [],
        message: '',
        logo: process.env.VUE_APP_URL_LOGO,
        otherLogo: true,
      },
    }
  },
  mounted() {
    this.$requests.get(`/version.json?ts=${new Date().getTime()}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
    .then(({version, changelogs, reporting}) => {
      this.$store.state.reporting = reporting;
      this.buildSettings.latestVersion = version;
      this.buildSettings.changelogs = changelogs;
    })
    .finally(() => {
      if(false && this.buildSettings.version !== this.buildSettings.latestVersion) {
        this.buildSettings.message = `<em>Version actuelle : <b>${this.buildSettings.version}</b>.<br>Une nouvelle version est disponible : <b>${this.buildSettings.latestVersion}</b>.</em><br><br>`;
        if(this.buildSettings.changelogs.length > 0) {
          this.buildSettings.message += 'Voici les changements :<br>';
          this.buildSettings.changelogs.forEach(change => {
            this.buildSettings.message += `<li>${change}</li>`;
          });
          this.buildSettings.message += '<br>';
        }

        this.buildSettings.message += '<em>Comment mettre à jour le Portail Magasin ?<br></em>';
        this.buildSettings.message += '<b>Mac :</b> Maintenez ⇧ Maj et cliquez sur le bouton Recharger <i class="far fa-redo-alt"></i>.<br>Ou maintenez ⌘ Cmd et ⇧ Touche Maj, puis appuyez sur R.<br>' +
          '<b>Windows/Linux :</b> Maintenez la touche Ctrl enfoncée et appuyez sur la touche F5. <br>Ou maintenez la touche Ctrl enfoncée et ⇧ Maj, puis appuyez sur R.<br>' +
          '<b>Internet Explorer :</b> Maintenez la touche Ctrl enfoncée et appuyez sur la touche F5.<br>Ou, maintenez la touche Ctrl enfoncée et cliquez sur le bouton Rafraîchir.';

        this.$buefy.notification.open({
          indefinite: true,
          message: this.buildSettings.message,
          type: 'is-dark',
          size: 'is-small',
          position: 'is-top-right',
          queue: false,
        });
      }
    });
  }
}
</script>
